<template>
  <div>
    <!-- Teacher switching tab -->
    <div class="flex items-center rounded-md mb-5 mt-1.5 pr-5 md:pr-0">
      <UIExit label="Exit teacher profile" />
      <!-- Switch Teacher  dropdown -->
      <div class="flex-1 flex justify-end">
        <div class="w-full xm:max-w-2.5">
          <div class="px-3.5 py-1 bg-white rounded-lg border border-primary-grey flex items-center">
            <SwitchTeacherDropdown />
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import layoutConstants from '@src/constants/layout-constant.js'
import SwitchTeacherDropdown from '@/src/router/views/teacher/profile/SwitchTeacherModal.vue'
import UIExit from '@/src/components/UiElements/UIExit.vue'

export default {
  components: {
    SwitchTeacherDropdown,
    UIExit,
  },
  data() {
    return {
      rightBar: 'rightBar',
    }
  },
  created() {
    this.setLeftBar()
  },
  methods: {
    /**
     * Exit teacher mode and redirect to Teachers page
     * Remove current user from localStorage
     */
    setLeftBar() {
      this.setLeftbarContent({
        back: '',
        customeHeader: 'left-bar-class-list',
        SHOW_SECTION_DROPDOWN_TO: layoutConstants.showSectionDropdownTo,
        SHOW_CLASS_DROPDOWN_TO: layoutConstants.showClassDropdownTo,
        routes: [
          {
            id: '001',
            name: 'Dashboard',
            route: { name: 'teacher-profile-dashboard' },
            role: 'super_admin',
          },
          {
            id: '002',
            name: 'Profile',
            route: { name: 'teacher-profile' },
            role: ['super_admin', 'section_teacher'],
          },
          {
            id: '03',
            name: 'PERIOD_ATTENDANCE_TEACHER_MODE',
            role: ['super_admin', 'section_teacher', 'section_student', 'guardian'],
            showChilds: false,
            basedOn: ['section-level'],
            routesName: ['teacher-myattendance', 'student-attendance'],
            childs: [
              {
                name: 'MY_ATTANDANCE_TEACHER_PROFILE_MODE',
                route: { name: 'teacher-myattendance' },
                role: ['super_admin', 'section_teacher'],
              },
              {
                name: 'TEACHER_MODE_STUDENT_ATTANDANCE',
                route: { name: 'student-attendance' },
                role: ['super_admin', 'section_teacher', 'section_student', 'guardian'],
              },
            ],
          },
          {
            id: '04',
            name: 'Schedule',
            route: { name: 'teacher-schedules' },
            basedOn: ['section-level'],
            role: ['super_admin', 'section_teacher'],
          },
          {
            id: '05',
            name: 'Students',
            route: { name: 'section-students' },
            role: ['super_admin', 'section_teacher'],
          },
        ],
      })
    },
    ...mapActions('layout', ['setLeftbarContent']),
  },
}
</script>

<style lang="scss" scoped>
.exit-student-mode {
  display: block;
}
</style>
