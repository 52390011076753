<template>
  <UiOnClickOutside :do="closeIsOpen">
    <div :class="$style.container" class="w-full">
      <div class="flex items-center justify-center h-7 rounded-md w-full">
        <div
          class="flex w-full cursor-pointer justify-between font-bold text-lg items-center focus:outline-none text-text-color-darker"
          type="button"
          @click="toggleIsOpen"
        >
          <div class="w-full">
            <div class="flex items-center">
              <div
                class="ltr:mr-3 overflow-hidden rtl:ml-3 h-7 w-7 rounded-full cursor-pointer bg-gray-300 flex justify-center items-center"
              >
                <img
                  v-if="currentTeacher && currentTeacher.image"
                  class="object-cover h-full w-full"
                  :src="currentTeacher.image"
                  alt="user img not found"
                />
                <span v-else class="text-xs">
                  {{ initials(`${currentTeacher.first_name} ${currentTeacher.last_name}`) }}
                </span>
              </div>
              <div
                class="capitalize w-3/5 font-roboto font-normal text-base text-label-text truncate"
              >
                {{ currentTeacher.first_name }} {{ currentTeacher.last_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center h-full">
          <button
            type="button"
            class="h-5 w-7 rounded flex items-center justify-center focus:outline-none"
            @click="toggleIsOpen"
          >
            <icon class="icon" icon="chevronBottom" color="menu" height="6" width="11" />
          </button>
        </div>

        <UiMenuDropDown :class="$style.dropDownPosition" :is-open="isOpen">
          <div class="flex items-center h-auto md:py-3 pt-1 pb-2 border-b border-border-line">
            <span class="absolute ltr:left-5 rtl:right-5">
              <icon class="icon" icon="search" color="menu" height="12" width="12" />
            </span>
            <input
              v-model="searchQuery"
              type="text"
              name="search"
              placeholder="Search"
              class="h-10 bg-bg-color-light rounded text-sm md:text-base focus:outline-none text-menu font-roboto px-8 w-full"
              @keyup="filterData"
            />
          </div>
          <ul
            class="font-roboto py-2.5 w-auto font-normal text-sm md:text-base text-text-color max-h-60 overflow-auto border-b border-border-line"
          >
            <li
              :class="$style.list"
              class="flex ltr:flex-row rtl:flex-row-reverse pr-2 items-center justify-between h-9 px-1.5 rounded hover:text-primary-green bg-primary-green-trans hover:font-bold"
            >
              <span class="campus-name selected-title">
                {{ currentTeacher.first_name }} {{ currentTeacher.last_name }}
              </span>
            </li>
          </ul>
          <ul class="h-64 overflow-y-auto">
            <div v-if="filterData.length > 0" class="pt-1">
              <li
                v-for="(teacher, index) in teacherList"
                :key="index"
                class="cursor-pointer flex text-base text-roboto p-1 rounded hover:text-primary-green hover:bg-bg-color-light items-center"
                @click="switchUser(teacher)"
              >
                <div class="class-name">
                  <div>{{ teacher.full_name }}</div>
                </div>
              </li>
            </div>
            <div v-else class="flex justify-center items-center">
              <p class="text-base py-2 text-center text-action-bg">No User found</p>
            </div>
          </ul>
        </UiMenuDropDown>
      </div>
    </div>
  </UiOnClickOutside>
</template>

<script>
import UiMenuDropDown from '@components/UiElements/UiMenuDropDown.vue'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import icon from '@components/icons/icon.vue'
import { mapState, mapActions } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import { filterdData } from '@src/utils/generalUtil.js'

export default {
  components: {
    UiMenuDropDown,
    UiOnClickOutside,
    icon,
  },

  mixins: [generalMixin],

  data() {
    return {
      isOpen: false,
      isRoleList: false,
      searchQuery: '',
      teacherList: [],
      optionsArray: [],
      forceReRender: 1,
      get currentTeacher() {
        return JSON.parse(localStorage.getItem('currentActiveTeacher'))
      },
      set currentTeacher(value) {
        JSON.parse(localStorage.getItem('currentActiveTeacher'))
      },
    }
  },
  computed: {
    ...mapState({
      classId: (state) => state.layout.currentSectionScope?.id,
      campusId: (state) => state.layout.currentCampusScope?.id,
      listenStorage: (state) => state.teacher.listenStorage,
    }),
    /**
     * Filter Data
     * @returns {String} - filtered String
     * @description filter data from options list by input search query returning the filtered list.
     */
    filterData() {
      const query = this.searchQuery.toLowerCase()
      return filterdData(query, this.teacherList, this.currentTeacher)
    },
  },
  watch: {
    optionsArray: {
      immediate: true,
      handler(value) {
        if (value.length > 0) this.teacherList = JSON.parse(JSON.stringify(value))
      },
    },
  },
  /**
   * Get teacher when page is created
   */

  created() {
    this.filterRecord()
  },
  methods: {
    /**
     * Toggle teacher dropdown
     */

    toggleIsOpen() {
      this.isOpen = !this.isOpen
    },

    toggleIsRoleList() {
      this.isRoleList = !this.isRoleList
    },
    closeIsOpen() {
      if (this.isOpen && this.searchQuery) {
        this.searchQuery = ''
      }
      this.isOpen = false
    },

    /**
     * Fetch section bases teacher
     * Set teacher records
     */

    filterRecord() {
      this.isLoading = true
      this.getTeacherList({
        campus_id: this.campusId,
        section_id: this.classId,
        role_id: 'section_teacher',
      }).then((res) => {
        this.optionsArray = res.data.records
        if (this.optionsArray.length > 0) {
          this.optionsArray.forEach(
            (record) => (record.full_name = `${record.first_name} ${record.last_name}`),
          )
        }
      })
    },

    /**
     * Switch teacher
     * Close dropdown
     * Navigate to profile page if user is not there
     */

    switchUser(teacher) {
      if (teacher) localStorage.setItem('currentActiveTeacher', JSON.stringify(teacher))
      this.isOpen = false
      this.searchQuery = ''
      this.$router?.push({ name: 'teacher-profile' })
      this.forceReRender++
      this.$store.commit('teacher/SET_LISTEN_STORAGE', !this.listenStorage)
    },
    ...mapActions('teacher', ['getTeacherList']),
  },
}
</script>

<style lang="scss" module>
.dropDownPosition {
  top: 57px;
  left: -4px !important;
  width: 100%;
  border-radius: 9px;
  box-shadow: 1px 1px 15px #0003;
}

body[dir='ltr'] {
  .dropDownPosition {
    right: -32px;
    left: -9px;
  }
}

body[dir='rtl'] {
  .dropDownPosition {
    right: -9px;
    left: -32px;
  }
}

.whoflogo {
  width: 22px;
  height: 22px;
}
.dropDownRolePosition {
  top: 45px;
  right: -5px;
}
.container {
  position: relative;
}
.titleContainer {
  display: flex;
  align-items: center;
}
.textBaseSm {
  font-size: 0.9em;
}
</style>

<style>
.roleStyling {
  margin-left: 8px;
  background: red;
}

.selected-class {
  color: var(--primary-green);
}

.class-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
